import React, { useEffect } from 'react';
import { PageProps } from 'gatsby';
import { useDispatch } from 'react-redux';
import { clearUserAnswers } from 'state/user/effects';
import { useRouter } from 'apis/history';

import { NavigationMenuButton } from 'layouts/navigations/NavigationMenuButton';
import { Header } from './sections/Header';

const Landing: React.FC<PageProps> = React.memo(({ location }) => {
  const { goToNotFound } = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname?.[1]) goToNotFound();
    dispatch(clearUserAnswers());
  }, []);

  return (
    <>
      <NavigationMenuButton />
      <Header />
    </>
  );
});

Landing.displayName = 'Landing';

export default Landing;
