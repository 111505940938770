import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import {
  Container,
  H1,
  H4,
  Regular,
} from '@kilornd/modules-wrapper/es/components';
import {
  useQuery,
  xsmDesktop,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Image, PrimaryButton, SectionWrapper } from 'components';

export const Header: React.FC = () => {
  const { isMobile, isTablet } = useQuery();

  return (
    <SectionWrapperStyled>
      <Container>
        {isTablet && <Image margin="0 0 0.5rem" src="lp_image_mobile" />}
        <H1 margin="0 0 1rem">
          Quit smoking easily.{!isMobile && <br />} No struggle.
          {!isMobile && <br />} No withdrawals.
        </H1>
        <ContentWrapper>
          <Regular margin="0 0 2rem">
            Find out how behavioral science-based program will help you break
            the habit completely.
          </Regular>
          <Link to="/quiz">
            <PrimaryButton
              id="landing__goToQuiz_button"
              data-testid="landing__goToQuiz_button"
              margin={isTablet ? '0 0 2.5rem' : '0 0 3.75rem'}
              maxWidth={isMobile ? '100%' : '16rem'}
            >
              Start a quiz
            </PrimaryButton>
          </Link>
          <H4 margin="0 0 1rem">How the ZeroSmokes® method works</H4>
          <Image
            margin={isMobile ? '0 auto' : '0'}
            maxWidth="18.6rem"
            src="how_method_works"
          />
        </ContentWrapper>
      </Container>
      {!isTablet && <ImageLargeStyled margin="0 0 0.5rem" src="lp_image" />}
    </SectionWrapperStyled>
  );
};

const SectionWrapperStyled = styled(SectionWrapper)`
  margin: 0;
  padding: 3rem 0;
`;

const ContentWrapper = styled.div`
  max-width: 28rem;
`;

const ImageLargeStyled = styled(Image)`
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 70rem;
  z-index: -1;

  @media ${xsmDesktop} {
    max-width: 40rem;
  }
`;
